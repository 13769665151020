@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@import "assets/styles/_mixins";
@import "assets/styles/components/download-item";
@import "assets/styles/components/download-container";

* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: #e5e5e5;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  border-radius: 5px;
  padding: 15px 15px 15px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include container;
}

.footer {
  max-width: 888px;
  width: 100%;
  margin: 0 auto 19px auto;
  border-radius: 10px;
  padding: 16px 0;
  font-size: 14px;
  text-align: center;
  @include container;

  p {
    margin: 0;
  }

  &-link {
    text-decoration: none;
    color: #0e7d7d;
    font-weight: bold;
  }
}

.client-page {
  position: relative;
  max-width: 888px;
  width: 100%;
  min-height: 620px;
  margin: 40px auto 10px auto;
  border-radius: 10px;
  text-align: center;
  overflow-y: auto;
  .client-upload-title{
    margin-bottom: 10px;
  }
  @include container;
  .client-upload-files {
    display: flex;
    max-width: 540px;
    width: 100%;
    flex-direction: column;
    border-radius: 10px;
    margin: 0 auto;

    h1 {
      text-align: center;
      font-size: 20px;
      margin-top: 12px;
      margin-bottom: 32px;
    }
    &-container {
      margin: 0 auto;
      max-width: 90%;
      padding: 20px 20px 40px 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &-container-wrapper {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }
  }

  h1 {
    font-size: 30px;
    color: #0e7d7d;
    margin: 45px 0;
  }

  .download-icon {
    width: 15px;
    height: 14px;
  }

  h3 {
    font-size: 20px;
    color: #0e7d7d;
    font-weight: bold;
  }
}

.icon {
  margin-left: 16px;
}

.admin-page {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  box-sizing: border-box;

  .admin-upload-files {
    display: flex;
    max-width: 660px;
    width: 100%;
    flex-direction: column;
    border-radius: 10px;
    @include container;

    h1 {
      text-align: center;
      font-size: 20px;
      margin-top: 12px;
      margin-bottom: 32px;
    }

    &-container {
      margin: 0 auto;
      max-width: 66%;
      padding: 20px 20px 40px 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &-container-wrapper {
      display: flex;
      flex-direction: column;
    }

    .mw-70 {
      max-width: 70%;
    }
    .mw-80 {
      max-width: 80%;
    }

    .add-icon-margin {
      margin-left: 20px !important;
    }

    .MuiOutlinedInput-input {
      padding: 9.5px 44px 9.5px 14px !important;
    }
  }
}

.upload-file-name {
  margin-bottom: 10px;
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-file-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  margin: 0 auto 20px auto;
  border-radius: 3px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);

  .MuiButton-root:hover {
    background-color: #0e7d7d !important;
    opacity: 0.7;
  }

  &-items {
    max-width: 292px;
    width: 100%;
    margin-right: 10px;
  }
}

.download-link-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #0e7d7d;
  box-sizing: border-box;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 1px 4px 1px 22px;
  font-weight: 600;
  font-size: 14px;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }
}

.download-link-description {
  font-size: 14px;
  color: #444444;
  margin: 0 0 4px 0;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-page {
  position: relative;
  min-height: 320px;
}

.overflow-y {
  overflow-y: auto;
}

.fileInput {
  display: none;
}

.d-flex {
  box-sizing: border-box;
  padding: 20px 30px 20px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-0 {
  padding: 0;
}
.m-1 {
  margin: 1rem;
}

.wh-0 {
  width: initial !important;
  height: initial !important;
}
