.download-container {
  max-width: 540px;
  width: 100%;
  margin: 0 auto 20px auto;
  background: #F7F7F7;
  border-radius: 10px;

  &:last-child {
    margin: 0 auto 40px auto;
  }

  &-header {
    display: flex;
    padding: 7px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E9E9E9;

    .text {
      display: flex;
      margin-left: 16px;
      font-size: 14px;
    }

    p {
      margin: 5px 0;
    }

    &-item-count {
      color: #0E7D7D;
    }
  }

  .pb-12 {
    padding-bottom: 12px;
  }

  &-items {
    overflow-y: scroll;
    padding: 5px 0px;
    max-height: 150px;
    height: 100%;
    box-sizing: border-box;
    margin: 4px 9px 4px 22px;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #E9E9E9;
    }

    &::-webkit-scrollbar-thumb {
      background: #AEAEAE;
      border: 2px solid #AEAEAE;
      border-radius: 3px;
    }
  }
}