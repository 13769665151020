.main-container{
  .all-name{
    color:#0E7D7D;
    margin: 10px 0;
  }
  .visited-container {
    background: #F9F9F9;
    border-radius: 3px;
    padding: 10px 10px 10px 4px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .row-container{
      width: 85%;
    }
    .align-items-none {
      align-items: initial;
    }

    .second-row-m {
      margin-top: 6px;
    }

    .image-container{
      display: flex;

    }
    .icon-container{
      display: flex;
      width:15%
    }
    .icon-close {
      align-self: flex-start;
      cursor: pointer;
      margin:0 10px;
    }

    .icon-edit {
      align-self: flex-start;
      cursor: pointer;
      margin:0 10px;
    }

    &-row {
      display: flex;
      gap: 15px;
      align-items: center;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .paper-container {
        width: 80%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 14px;
        position: relative;
        &.main-link{
          &>div{
            max-width: 90%;
          }

          &:after{
            top: -31px;
            right: 0;
            content: "copied!";
            opacity: 0;
            position: absolute;
            font-size: 16px;
          }
          &:active:after{
            opacity: 1
          }
        }
      }

      h3 {
        flex-basis: 20%;
        font-size: 14px;
        text-align: right;
        margin: 0;
      }

      p {
        margin: 6px 15px;
      }

      h4 {
        margin: 7px 15px;
        font-size: 14px;
      }

      .grey {
        color: #444444;
        opacity: .7;
        font-weight: 400;
        flex-basis: 18%;
      }
    }
  }
}
