@import "../_mixins.scss";

.drop-container {
  flex: 2;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 215px;
  width: 100%;
  font-size: 14px;
  border-radius: 10px;
  font-weight: 600;
  color: #444444;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);

  &-plus {
    background: #F7F7F7;
    border-radius: 3px;
    font-size: 30px;
    cursor: pointer;
  }

  p {
    margin-bottom: 18px;
    font-size: 14px;
  }
}

.file-drop {
  width: 100%;
  height: 100%;
  position: relative;
}

.file-drop > .file-drop-target {
  box-sizing: border-box;
  color: #444444;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  color: #444444;
  border: none;
  box-shadow: none;
  z-index: 50;
  opacity: .5;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  color: #444444;
  box-shadow: 0 0 13px 3px #4aa34a;
}

.grow {
  flex-grow: 2;
}

.with-files {
  height: auto;
}

.icon-close-margin {
  margin-right: 13px !important;
}