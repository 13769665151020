.download-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &-file {
    padding: 7px 20px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 10px;
    flex-grow: 1;
    cursor: pointer;
    overflow-x: scroll;
    img {
      margin-right: 20px;
    }

    p {
      font-size: 14px;
      margin: 0;
      color: #3B3B3B;
      opacity: 0.5;
      overflow-x: scroll;
    }
  }
}
